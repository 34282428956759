<template>
  <div class="mt-4">
    <div v-if="user.id === quotation.requiredById">
      <v-alert v-if="quotation.status == 'pending'" color="warning">
        <v-icon left> mdi-alert </v-icon>
        <template v-if="finishProviders.length !== quotation.Providers.length">
          <span class="font-weight-bold">Atenção:</span> Nenhum orçamento foi escolhido ainda.
          {{ finishProviders.length }} de {{ quotation.Providers.length }} prestador{{
            quotation.Providers.length > 1 ? 'es' : ''
          }}
          já responderam a solicitação de orçamento{{ finishProviders.length > 1 ? 's' : '' }}.
        </template>
        <template v-else>
          <span class="font-weight-bold">Atenção:</span> Você não escolheu um orçamento ainda. Todos os prestadores
          responderam a solicitação de orçamento.
        </template>
      </v-alert>
    </div>
    <div v-if="myQuotation">
      <v-alert v-if="myQuotation.status == 'pending'" color="warning">
        <div class="d-flex">
          <v-icon left> mdi-alert </v-icon>
          <div class="flex-grow-1">
            <span class="font-weight-bold">Atenção:</span> Você não respondeu a solicitação de orçamento ainda.
          </div>
          <v-btn @click="answer" color="primary" small :loading="loadingStart"> Responder </v-btn>
        </div>
      </v-alert>
      <v-alert v-else color="success">
        <v-icon left> mdi-check </v-icon>
        <span v-if="myQuotation.status == 'accepted'" class="font-weight-bold">
          Você enviou um orçamento no valor de {{ myQuotation.value | currency }}.
        </span>
        <span v-else class="font-weight-bold"> Você recusou o orçamento. </span>
      </v-alert>
    </div>

    <div v-if="user.id === quotation.requiredById || hasPermission([1073741824])">
      <h3 class="mb-2">Prestadores</h3>
      <v-row>
        <v-col v-for="provider in quotation.Providers" :key="provider.Provider.id" cols="12" sm="6" md="4">
          <v-card outlined class="pa-3">
            <h5>
              {{ provider.Provider.name }}
            </h5>

            <div v-if="provider.value" class="text-center">
              <h4>
                {{ provider.value | currency }}
              </h4>
              <p class="font-weight-bold mb-0">Valor</p>
            </div>
            <div v-else class="text-center">
              <h4>Não respondido</h4>
              <p class="font-weight-bold mb-0">Valor</p>
            </div>
            <p class="mb-0">
              {{ provider.description }}
            </p>
            <v-btn
              v-if="quotation.status == 'pending' && user.id === quotation.requiredById && provider.value"
              @click="finish(provider)"
              color="primary"
              class="mt-2"
              block
              small
            >
              Escolher
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <AnswerModal
      v-if="quotation.status == 'pending'"
      :quotation="quotation"
      :loading="loading"
      @success="$emit('refresh')"
    />
    <!-- <FinishModal
      v-if="quotation.status == 'inProgress'"
      :quotation="quotation"
      :loading="loading"
      @success="$emit('refresh')"
    /> -->
    <ServiceRequestModal />
  </div>
</template>
<script>
import serviceStatus from '@/utils/serviceStatus'
import { mapGetters } from 'vuex'
import AnswerModal from './modal/QuotationAnswerModal.vue'
import ServiceRequestModal from '@/components/services/modal/ServiceRequestModal.vue'

// import FinishModal from './modal/ServiceRequestFinishModal.vue'
import SERVICES from '@/services/services'

export default {
  data: () => ({
    serviceStatus,
    loadingStart: false,
  }),
  methods: {
    answer() {
      this.$emit('answer')
    },
    finish(provider) {
      this.$root.$emit('service-request', { provider, quotation: this.quotation })
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'user']),
    finishProviders() {
      return this.quotation.Providers.filter(provider => provider.status !== 'pending')
    },
    startLog() {
      return this.quotation.ServiceRequestLog.find(log => log.action === 'start')
    },
    myQuotation() {
      return this.quotation.Providers.find(provider => provider.Provider.id === this.user.id)
    },
  },
  components: {
    AnswerModal,
    ServiceRequestModal,
    // FinishModal,
  },
  props: {
    loading: Boolean,
    quotation: Object,
  },
}
</script>
