<template>
  <v-row>
    <v-col cols="">
      <v-card outlined>
        <v-card-title class="d-flex gap-x-2 gap-y-2 align-center">
          <h5>{{ quotation.Service.name }}</h5>
          <v-chip label :color="serviceStatus[quotation.status].color" small>
            <v-icon x-small class="pr-1">{{ serviceStatus[quotation.status].icon }}</v-icon>
            {{ serviceStatus[quotation.status].text }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <v-card outlined class="pa-2">
            <p class="font-weight-bold">Descrição</p>
            <p class="mb-0">{{ quotation.description }}</p>
          </v-card>

          <div class="d-flex flex-wrap gap-x-4 gap-y-1 mt-4 justify-space-around">
            <div>
              <v-icon small left> mdi-account </v-icon>
              <span class="font-weight-bold">Solicitado por:</span>
              {{ quotation.RequiredBy.name }}
            </div>
            <div>
              <v-icon small left> mdi-account-wrench </v-icon>
              <span class="font-weight-bold">Responsáveis:</span>
              {{ quotation.Providers.length }} prestador{{ quotation.Providers.length > 1 ? 'es' : '' }}
            </div>
            <div>
              <v-icon small left> mdi-calendar </v-icon>
              <span class="font-weight-bold">Solicitado em:</span>
              {{ quotation.createdAt | date }}
            </div>
            <div v-if="isExport">
              <v-icon small left> mdi-calendar </v-icon>
              <span class="font-weight-bold">Endereço:</span>
              {{ quotation.Immobile.Address.street }}, {{ quotation.Immobile.Address.number }} -
              {{ quotation.Immobile.Address.city }} - {{ quotation.Immobile.Address.state }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="3" md="3" lg="2" v-if="quotation.Immobile && !isExport">
      <v-card class="w-full h-full" @click="openMap()">
        <v-img :src="quotation.Immobile.Address.mapUrl" height="100%" :aspect-ratio="1"> </v-img>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import serviceStatus from '@/utils/serviceStatus'
export default {
  name: 'ServiceHeader',
  data: () => ({
    serviceStatus,
  }),
  methods: {
    openMap() {
      const address = `${this.quotation.Immobile.Address.street}, ${this.quotation.Immobile.Address.number} - ${this.quotation.Immobile.Address.city} - ${this.quotation.Immobile.Address.state}`
      window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank')
    },
  },
  props: {
    loading: Boolean,
    quotation: Object,
    isExport: Boolean,
  },
}
</script>
