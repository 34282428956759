var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","hide-overlay":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Responder Solicitação de Orçamento ")]),_c('v-card-text',[_c('v-btn-toggle',{staticClass:"w-full mb-5",attrs:{"color":_vm.accepted ? 'success' : 'error'},model:{value:(_vm.accepted),callback:function ($$v) {_vm.accepted=$$v},expression:"accepted"}},[_c('v-btn',{staticClass:"flex-grow-1",attrs:{"value":true}},[(_vm.accepted)?_c('v-icon',{attrs:{"small":"","left":"","color":"success"}},[_vm._v("mdi-check")]):_vm._e(),_vm._v(" Aceitar ")],1),_c('v-btn',{staticClass:"flex-grow-1",attrs:{"value":false}},[(!_vm.accepted)?_c('v-icon',{attrs:{"small":"","left":"","color":"error"}},[_vm._v("mdi-close")]):_vm._e(),_vm._v(" Recusar ")],1)],1),_c('v-text-field-money',{attrs:{"label":"Valor","properties":{
          prefix: 'R$',
          placeholder: ' ',
          outlined: true,
        },"options":{
          outlined: true,
          locale: 'pt-BR',
          length: 11,
          precision: 2,
          empty: null,
        }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('v-textarea',{attrs:{"rules":[
          v => !!v || 'Campo obrigatório',
          v => v.length <= 255 || 'Máximo de 255 caracteres',
          v => v.length >= 10 || 'Mínimo de 10 caracteres',
        ],"label":_vm.accepted ? 'Descrição' : 'Motivo da recusa',"outlined":"","rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-card-actions',{staticClass:"px-8 pb-8"},[_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"color":"primary","disabled":_vm.loading,"outlined":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v("Confirmar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }