<template>
  <div class="d-flex flex-column gap-4">
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <div v-if="quotation">
      <QuotationHeader :loading="loading" :quotation="quotation" />
      <QuotationBody :loading="loading" :quotation="quotation" @refresh="getData" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import QuotationHeader from '@/components/quotation/QuotationHeader.vue'
import QuotationBody from '@/components/quotation/QuotationBody.vue'
import QUOTATION from '@/services/quotation'

export default {
  name: 'quotationDetails',
  data() {
    return {
      quotation: null,
      loading: false,
      error: null,
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        this.error = null
        const { quotation } = await QUOTATION.byId(this.$route.params.id)
        this.quotation = quotation
      } catch (error) {
        this.error = error.message || error
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.getData()
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  components: { QuotationHeader, QuotationBody },
}
</script>
